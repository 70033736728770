import { Box, IconButton, Modal, Typography } from "@mui/material";
import React from "react";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

export default function Img({ src }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Typography
        variant="h6"
        component="h2"
        textAlign="right"
        sx={{ color: "primary.main" }}
      >
        صورة المشكلة :
      </Typography>
      <Box
        onClick={handleOpen}
        sx={{
          border: "1px solid green",
          "&:hover": { border: "1px solid black" },
          textAlign: "center",
        }}
      >
        <img
          src={src}
          alt="صورة-المشكلة"
          style={{
            maxWidth: "100%",

            maxHeight: "200px",
            cursor: "pointer",
          }}
        />
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography
            variant="h6"
            component="h2"
            textAlign="right"
            sx={{ color: "primary.main" }}
          >
            : صورة المشكلة
            <IconButton
              aria-label="delete"
              sx={{ float: "left" }}
              onClick={handleClose}
            >
              <HighlightOffIcon color="error" />
            </IconButton>
          </Typography>

          <img
            src={src}
            alt="صورة-المشكلة"
            style={{ maxWidth: "100%", cursor: "pointer" }}
          />
        </Box>
      </Modal>
    </Box>
  );
}
