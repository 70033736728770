import { Box, Button, MenuItem, Typography } from "@mui/material";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export const statusBtns = ["تم حلها", "مازالت يتم حلها"];
export const categoryBtns = ["مشكلة تقنية", "مشكلة داخلية", "مشكلة معلومات"];

export const columns = [
  {
    accessorKey: "email",
    header: "البريد الإلكترونى",
    size: 200,
    enableEditing: false,
  },
  {
    accessorKey: "isConfirmedStudent",
    header: "سجل الدخول بالفعل؟",
    size: 10,
    enableEditing: false,
    Cell: ({ row }) => {
      return (
        row.original.msg && (
          <Box sx={{ textAlign: "center" }}>
            {row.original.isConfirmedStudent ? (
              <CheckBoxIcon color="success" />
            ) : (
              <DisabledByDefaultIcon color="error" />
            )}
          </Box>
        )
      );
    },
  },
  {
    accessorKey: "msg",
    header: "وصف المشكلة",
    size: 200,
    enableEditing: false,
  },
  {
    accessorKey: "status",
    header: "الحالة",
    size: 30,

    muiEditTextFieldProps: () => ({
      children: statusBtns.map((func) => (
        <MenuItem key={func} value={func}>
          {func}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "category",
    header: "الفئة",
    size: 30,
    muiEditTextFieldProps: () => ({
      children: categoryBtns.map((func) => (
        <MenuItem key={func} value={func}>
          {func}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "isRead",
    header: "تمت قرائتها",
    size: 30,
    enableEditing: false,
    Cell: ({ row }) => {
      return (
        <Box sx={{ textAlign: "center" }}>
          {row.original.isRead ? (
            <Typography sx={{ color: "primary.main" }}>
              تمت قراءة المشكلة
            </Typography>
          ) : (
            <Button variant="contained">تم إستلامها</Button>
          )}
        </Box>
      );
    },
  },
];
