import { Box, Typography } from "@mui/material";
import React from "react";
import { problemsComponentsArray } from "./Components/ProblemsArray";
import axios from "../../api/axios";
import TextInput from "../inputs/TextInput";
import RowStatus from "./RowStatus";

export default function AdminProblemComponents({ row, setData, data }) {
  const [currentState, setCurrentState] = React.useState({
    state: "",
    stateMsg: "",
    department: row.department,
    forwardedDepartment: row.forwardedDepartment,
    submitted: false,
  });

  const [error, setError] = React.useState("");

  const onMsgChange = (e) => {
    setCurrentState({ ...currentState, stateMsg: e.target.value });
  };

  const onCancelAddingState = () => {
    setCurrentState({ state: "", stateMsg: "" });
  };

  const onSubmit = async () => {
    try {
      const res = await axios.patch("problem/" + row.id, { ...currentState });
      if (res?.data?.id) {
        const updatedData = data.map((r) => {
          if (r.id === row.id) {
            return { ...r, state: res.data.state, stateMsg: res.data.stateMsg };
          } else {
            return r;
          }
        });

        setData(updatedData);
        setCurrentState({ ...currentState, submitted: true });
        onCancelAddingState();
      }
    } catch (error) {
      console.error(error);
      setError("حدث خطأ في عملية إضافة الحالة.. الرجاء تحديث الصفحة.");
    }
  };
  console.log(row);

  const onDepartmentChange = async (e) => {
    const forwardedDepartment =
      e.target.value === row.department ? "" : e.target.value;
    setCurrentState({
      state: "",
      stateMsg: "",
      forwardedDepartment,
    });

    try {
      const res = await axios.patch("problem/" + row.id, {
        state: "",
        stateMsg: "",
        forwardedDepartment,
      });
      if (res?.data?.id) {
        const updatedData = data.map((r) => {
          if (r.id === row.id) {
            return {
              ...r,
              state: res.data.state,
              stateMsg: res.data.stateMsg,
              forwardedDepartment: res.data.forwardedDepartment,
            };
          } else {
            return r;
          }
        });

        setData(updatedData);
      }
    } catch (error) {
      console.error(error);
      setError("حدث خطأ في عملية إضافة الحالة.. الرجاء تحديث الصفحة.");
    }
  };

  return (
    <Box>
      {row.department === "آخرى" && (
        <TextInput
          label="أسم التخصص"
          disabled
          value={row.otherDepartmentName}
          sx={{ width: "50%" }}
        />
      )}
      <Typography
        variant="h6"
        sx={{ fontWeight: "600", mb: 1, color: "primary.main" }}
      >
        {row.problemType}
      </Typography>

      <Box sx={{ width: "50%" }}>
        {
          problemsComponentsArray({ row }).find(
            (p) => p.type === row.problemType
          ).component
        }
      </Box>

      <RowStatus
        row={row}
        currentState={currentState}
        setCurrentState={setCurrentState}
        onCancelAddingState={onCancelAddingState}
        onMsgChange={onMsgChange}
        onSubmit={onSubmit}
        error={error}
        onDepartmentChange={onDepartmentChange}
      />
    </Box>
  );
}
