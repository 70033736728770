import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { BootstrapInput } from "../inputs/BootstrapInputs";
import { managerSelections } from "./utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AppContext } from "../../Context/App.context";

export default function CategoryManager({
  selected,
  handleSelection,
  onAddSelection,
  error,
}) {
  const { user } = React.useContext(AppContext);

  return (
    <Paper
      sx={{
        my: 2,
        p: 2,
        display: "inline-block",
      }}
    >
      <Typography variant="h7" sx={{ fontWeight: "600" }}>
        مدير الفئات
      </Typography>

      <Box sx={{ mt: 2, display: "flex" }}>
        {managerSelections.map((ms) => {
          return (
            <Box
              sx={{ minWidth: 120, pl: 1, textAlign: "right" }}
              key={ms.value}
            >
              <FormControl
                variant="standard"
                size="small"
                sx={{ m: 1, ml: 0, mt: 0, minWidth: 120, textAlign: "right" }}
              >
                <InputLabel
                  id={ms.value}
                  shrink
                  sx={{ fontSize: "1.1rem", right: -40, fontWeight: "600" }}
                >
                  {ms.label}
                </InputLabel>
                <Select
                  labelId={ms.value}
                  name={ms.value}
                  value={selected[ms.value]}
                  onChange={handleSelection}
                  size="small"
                  input={<BootstrapInput />}
                >
                  {ms.value === "department" && user.department
                    ? ms.options
                        .filter((op) => op.label === user.department)
                        .map((d) => {
                          return (
                            <MenuItem
                              value={d.label}
                              key={d.label}
                              sx={{ textAlign: "right", pr: 1 }}
                            >
                              <ListItemText sx={{ display: "inline-block" }}>
                                {d.label === "آخرى" ? "مسجل الكلية" : d.label}
                              </ListItemText>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "text.secondary",
                                  px: 1,
                                  display: "inline-block",
                                }}
                              >
                                {d.icon}
                              </Typography>
                            </MenuItem>
                          );
                        })
                    : ms.options.map((d) => {
                        return (
                          <MenuItem
                            value={d.label}
                            key={d.label}
                            sx={{ textAlign: "right", pr: 1 }}
                          >
                            <ListItemText sx={{ display: "inline-block" }}>
                              {d.label === "آخرى" ? "مسجل الكلية" : d.label}
                            </ListItemText>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "text.secondary",
                                px: 1,
                                display: "inline-block",
                              }}
                            >
                              {d.icon}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Box>
          );
        })}

        <Button
          variant="contained"
          disabled={!selected.department || !selected.problem}
          sx={{ mt: 3, mr: 1, mb: 1.5 }}
          endIcon={<AddCircleOutlineIcon sx={{ mr: 1 }} />}
          onClick={onAddSelection}
        >
          أضف
        </Button>
      </Box>
      <Typography sx={{ color: "red", fontSize: "14px", px: 2 }}>
        {error}
      </Typography>
    </Paper>
  );
}
