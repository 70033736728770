import React, { createContext, useState } from "react";
import { jwtDecode } from "../../node_modules/jwt-decode/build/esm";
import api from "../api/axios";

const AppContext = createContext({});

function AppContextProvider({ children }) {
  const [user, setUser] = useState({ role: "visitor" });
  const [courses, setCourses] = useState([]);

  const setCurrentUser = async (accessToken) => {
    localStorage.removeItem("token");

    localStorage.setItem("token", accessToken);

    if (accessToken && accessToken !== "null") {
      const decodedToken = jwtDecode(accessToken);
      const res = await api.get("user/get-info/" + decodedToken.id);

      setUser(res.data);
    }
  };

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    console.log({ token });
    if (token && token !== "null") {
      const decodedToken = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = decodedToken.exp;

      if (expirationTime < currentTime) {
        // Token has expired, handle accordingly (e.g., log out user, refresh token)
        localStorage.removeItem("token");
        setUser({ role: "visitor" });
      } else {
        // Token is still valid
        const func = async () => {
          const res = await api.get("user/get-info/" + decodedToken.id);

          console.log("user context :", { res });

          setUser(res.data);
        };
        func();
      }
    }
  }, []);

  return (
    <AppContext.Provider
      value={{ user, setUser, setCurrentUser, courses, setCourses }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContextProvider, AppContext };
