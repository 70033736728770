import React from "react";
import Subject from "./Subject";
import { optionalSubjects } from "../../../../../Pages/StudentPage/StudentForm/utils";
import LabeledSelect from "../../../../inputs/LabeledSelect";
import { AppContext } from "../../../../../Context/App.context";
import api from "../../../../../api/axios";

export const subjectsInputs = [
  {
    label: "اسم المقرر",
    field: "name",
    placeholder: "--تحديد اسم المقرر--",
  },
  {
    label: "رقم المقرر",
    field: "number",
    placeholder: "--تحديد رقم المقرر--",
  },
  {
    label: "CRN",
    field: "crn",
  },
  {
    label: "رمز الشعبة",
    field: "sectionNumber",
  },
];
export default function SubjectRow({
  index,
  subject,
  disabled,
  onChangeSubject,
  onChangeOptionalSubject,
  state,
}) {
  const { courses } = React.useContext(AppContext);

  const [optionalCourses, setOptionalCourses] = React.useState([]);

  React.useEffect(() => {
    async function getOptionalData() {
      if (subject.optionalSubjectType) {
        // console.log({ opt: subject });
        try {
          const res = await api.get(
            `course/optional?optionalSubjectType=${subject.optionalSubjectType}&optionalDepartment=${subject.department}`
          );
          console.log({ res });
          if (res.data) {
            setOptionalCourses(res.data);
          }
        } catch (error) {}
      }
    }
    getOptionalData();
  }, [subject.optionalSubjectType]);
  return (
    <>
      {subject.optionalSubjectType ? (
        <>
          <LabeledSelect
            label={"المقرر"}
            name={"name"}
            onChange={(e) => onChangeSubject(e, index + 1)}
            value={subject.optionalSubjectType}
            disabled={disabled}
            options={courses.map((c) => c.subjectName)}
            size="small"
          />
          <Subject
            subject={subject}
            index={index}
            onChangeSubject={onChangeOptionalSubject}
            state={state}
            disabled={disabled}
            rowInputs={subjectsInputs}
            courses={optionalCourses}
          />
        </>
      ) : (
        <Subject
          subject={subject}
          index={index}
          onChangeSubject={onChangeSubject}
          state={state}
          disabled={disabled}
          rowInputs={subjectsInputs}
          courses={courses}
        />
      )}
    </>
  );
}
