import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import UnLabeledRadioBtns from "./UnLabeledRadioBtns";
import { categoryBtns, statusBtns } from "../Table/cols";
import LabeledSelect from "../../../Components/inputs/LabeledSelect";

export const isReadOptions = ["المقروء فقط", "الغير مقروء فقط"];

export default function FilterManager() {
  return (
    <Paper
      sx={{
        my: 2,
        p: 2,
        display: "inline-block",
      }}
    >
      <Typography variant="h7" sx={{ fontWeight: "600" }}>
        مدير بحث المشكلات
      </Typography>

      <Box sx={{ mt: 2, pl: 2, display: "flex" }}>
        <UnLabeledRadioBtns options={isReadOptions} />
        <UnLabeledRadioBtns options={statusBtns} />

        <Box sx={{ width: 200, pr: 3 }}>
          <LabeledSelect
            label={"تحديد الفئة"}
            options={["الكل", ...categoryBtns]}
            size={"small"}
          />
        </Box>
      </Box>
    </Paper>
  );
}
