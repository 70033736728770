import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import React from "react";
import SubRow from "./SubRow";
import { mainTableAttr } from "../../../Components/mainTableAttr";
import { columns } from "./cols";

export default function SupportTable({ data }) {
  const table = useMaterialReactTable({
    columns,
    data,
    ...mainTableAttr,
    enableEditing: true,
    editDisplayMode: "table",
    // enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    enableExpanding: true,
    renderDetailPanel: ({ row }) =>
      row.original.msg ? <SubRow row={row.original} /> : null,

    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.original.status !== "تم حلها" && "#f1f2f3",
      },
    }),
  });

  return <MaterialReactTable table={table} />;
}
