import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import React from "react";

import SubjectRow from "./Components/SubjectRow";
import FileInput from "../../inputs/FileInput";

const appConstraints = [
  "القيود على مجال الدراسة",
  "قيد قسم",
  "خطأ في المطلب الأساسي ودرجات الاختبار",
  "القيود الخاصة بالبرنامج",
  "قيود على الدفعة",
  "قيود المرحلة",
];
export default function ConstraintsSubDetailsInput({
  state,
  onChange,
  onChangeSubject,
  onChangeOptionalSubject,
  subjectsList,
  onChangeConstraints,
  onSelectFile,
}) {
  return (
    <>
      <Typography
        sx={{ fontWeight: "600", color: "primary.main", fontSize: "1.2rem" }}
      >
        نوع القيد :
      </Typography>
      <Box
        sx={{
          width: "400px",
          mb: 4,
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {appConstraints.map((c) => {
          return (
            <Box
              sx={{
                flexGrow: 1,

                display: "inline-block",
                color: "primary.main",
                fontSize: "14px",
                "& span": {
                  p: 0.3,
                },
              }}
              key={c}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.constraints.includes(c)}
                    onChange={(e) => {
                      onChangeConstraints(e, c);
                    }}
                  />
                }
                label={c}
                sx={{ color: "primary.main" }}
              />
            </Box>
          );
        })}
      </Box>

      {subjectsList?.map((s, i) => {
        return (
          <SubjectRow
            key={s.id}
            subject={s}
            index={i}
            onChangeSubject={onChangeSubject}
            onChangeOptionalSubject={onChangeOptionalSubject}
            state={state}
          />
        );
      })}

      <FileInput
        label="إرفاق صورة من القيد"
        name="constraintFile"
        value={state.constraintFile?.name}
        onSelectFile={onSelectFile}
      />
    </>
  );
}
