import { Divider, Grid2, TextField, Typography } from "@mui/material";
import React from "react";
import LabeledSelect from "../../../../inputs/LabeledSelect";

export default function Subject({
  subject,
  index,
  onChangeSubject,
  state,
  disabled,
  rowInputs,
  courses,
}) {
  return (
    <Grid2 container spacing={2} alignItems="center" sx={{ mt: 2 }}>
      {rowInputs.map((s) => {
        return (
          <Grid2
            size={{
              xs: s.field === "name" ? 8 : s.field === "number" ? 4 : 6,
              md: s.field === "name" ? 4 : s.field === "number" ? 2 : 3,
            }}
            key={s.label}
          >
            {state?.department !== "آخرى" && s.field === "name" ? (
              <LabeledSelect
                label={s.label}
                name={s.field}
                onChange={(e) => onChangeSubject(e, index + 1, courses)}
                value={subject?.[s.field]}
                disabled={disabled}
                options={courses.map((c) =>
                  s.field === "name" ? c.subjectName : c.subjectNumber
                )}
                placeholder={s.placeholder}
                size="small"
              />
            ) : (
              <>
                <Typography
                  sx={{
                    fontWeight: 600,
                    transform: "translateY(-4px)",
                    color: "primary.main",
                  }}
                >
                  {/* {s.label} {index + 1}: */}
                  {s.label} :
                </Typography>
                <TextField
                  name={s.field}
                  value={subject?.[s.field]}
                  onChange={(e) => onChangeSubject(e, index + 1, courses)}
                  disabled={
                    state?.department !== "آخرى" && s.field === "number"
                      ? true
                      : disabled
                  }
                  variant="outlined"
                  size="small"
                  fullWidth
                  sx={{ transform: "translateY(-4px)" }}
                />
              </>
            )}
          </Grid2>
        );
      })}
      <Divider />
    </Grid2>
  );
}
