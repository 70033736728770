import api from "../../../api/axios";
import { getInitialProblemData, problemSelectionPlaceholder } from "../utils";
import { problemTypes } from ".";

export const handleSubmitForm = async ({
  problemData,
  setProblemData,
  error,
  setError,
  setSuccess,
  setResError,
  setLoading,
}) => {
  console.log("submittedData: ", problemData);
  const subjects = [];

  problemData.subjects.forEach((s) => {
    if (s.name || s.crn || s.sectionNumber) {
      if (!s.name || !s.crn || !s.sectionNumber) {
        // setError("يجب استكمال بيانات المقرر " + s.id + " أو حذف بياناته");
        setError("يجب استكمال بيانات المقرر ");
      } else {
        delete s.id;
        subjects.push(s);
      }
    }
  });

  try {
    if (problemData.problemType === problemSelectionPlaceholder) {
      setError("رجاء تحديد المشكلة!");
    } else if (problemData.problemType !== problemTypes[4] && !subjects[0]) {
      setError("رجاء استكمال بيانات المقرر");
    } else if (
      [
        problemTypes[0],
        problemTypes[1],
        problemTypes[3],
        problemTypes[4],
      ].includes(problemData.problemType) &&
      !problemData.gainedHours
    ) {
      setError("رجاء إدخال عدد الساعات المكتسبة.");
    } else if (
      [problemTypes[4]].includes(problemData.problemType) &&
      !problemData.requiredHours
    ) {
      setError("رجاء إدخال عدد الساعات المطلوبة فى التسجيل.");
    } else if (
      [problemTypes[2]].includes(problemData.problemType) &&
      !problemData.constraints[0]
    ) {
      setError("رجاء إختيار قيد واحد على الأقل.");
    } else if (!problemData.scheduleFile) {
      setError("رجاء إرفاق ملف الجدول الدراسى.");
    } else if (!problemData.academicFile) {
      setError("رجاء إرفاق السجل الأكاديمى");
    } else if (
      [problemTypes[2]].includes(problemData.problemType) &&
      !problemData.constraintFile
    ) {
      setError("رجاء إرفاق صورة من ملف القيد.");
    } else {
      const formData = new FormData();
      formData.append("scheduleFile", problemData.scheduleFile);
      formData.append("academicFile", problemData.academicFile);
      if (problemData.constraintFile)
        formData.append("constraintFile", problemData.constraintFile);

      // console.log({ ...problemData, subjects });
      formData.append("data", JSON.stringify({ ...problemData, subjects }));

      if (!error) {
        // for (var pair of formData.entries()) {
        //   console.log(`${pair[0]}: ${pair[1]}`);
        // }
        setLoading(true);
        const res = await api.post("/problem", formData, {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type explicitly
          },
        });
        if (res.data?.id) {
          console.log({ data: res.data });

          setSuccess("تم إضافة الشكوى بنجاح.. سيتم الرد عليها فى أقرب وقت.");
          setProblemData(
            getInitialProblemData({ department: res.data?.department })
          );
          setResError("");
          setLoading(false);
        } else {
          setResError("حدث خطأ فى إضافة الشكوى.. الرجاء المحاولة مرة آخرى.");
          setLoading(false);
        }
      }
    }
  } catch (error) {
    console.log(error);
    setSuccess("");
    setResError("حدث خطأ فى إضافة الشكوى.. الرجاء المحاولة مرة آخرى.");
  }
};
