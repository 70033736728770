import { Box } from "@mui/material";
import React from "react";
import ChatBox from "./ChatBox";
import { AppContext } from "../../Context/App.context";
import AdminSupportBtn from "./AdminSupportBtn";
import StudentSupportBtn from "./StudentSupportBtn";

export default function SupportBtn() {
  const { user } = React.useContext(AppContext);
  const [showChat, setShowChat] = React.useState(false);

  return (
    <Box sx={{ position: "relative" }}>
      {showChat && <ChatBox />}

      {user.role === "admin" ? (
        "" // <AdminSupportBtn />
      ) : (
        <StudentSupportBtn showChat={showChat} setShowChat={setShowChat} />
      )}
    </Box>
  );
}
