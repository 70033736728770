import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import TextInput from "../../Components/inputs/TextInput";
import { useNavigate } from "react-router-dom";
import api from "../../api/axios";

export default function FpSendEmail() {
  const [data, setData] = React.useState({
    email: "",
  });

  const navigate = useNavigate();

  const [error, setError] = React.useState("");
  const [result, setResult] = React.useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setResult("");
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    if (!data.email) {
      setError("برجاء استكمال البيانات");
    }

    const postEmail = "@student.kfu.edu.sa";

    if (data.email.slice(-postEmail.length) !== postEmail) {
      setError("الرجاء إدخال البريد الالكترونى الجامعى الخاص بك.");
    }

    if (!error) {
      const res = await api.post("/auth/fpSendEmail", data);
      console.log({ res });
      if (res) {
        setResult(
          "تم إرسال رسالة تغيير كلمة المرور فى صندوق البريد الإلكترونى الخاص بك"
        );
      }
    }
  };

  return (
    <Paper sx={{ width: { xs: "85%", md: "600px" }, m: " 20px auto", p: 2 }}>
      <TextInput
        label="البريد الإلكترونى"
        value={data.email}
        name="email"
        onChange={onChange}
      />

      <Typography sx={{ color: "red", fontSize: "14px", textAlign: "center" }}>
        {error}
      </Typography>

      <Typography
        sx={{ color: "primary.main", fontSize: "14px", textAlign: "center" }}
      >
        {result}
      </Typography>

      <Button
        onClick={() => {
          navigate("/");
        }}
        sx={{ textDecoration: "underline", fontWeight: "600" }}
      >
        تسجيل الدخول
      </Button>

      <Button
        onClick={onSubmit}
        variant="contained"
        fullWidth
        sx={{ mt: 1, fontSize: "18px" }}
      >
        إرسال رسالة تجديد كلمة المرور
      </Button>
    </Paper>
  );
}
