import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import TextInput from "../../Components/inputs/TextInput";
import { loginInputs, signupInputs } from "./utils";
import { authApi } from "./api";
import RadioInput from "../../Components/inputs/RadioInput";
import { AppContext } from "../../Context/App.context";
import { useNavigate } from "react-router-dom";
import { fbSendEmailPath } from "../../routes/visitingParentRoutes";
import { postEmail } from "../../utils/vars";
import LabeledInput from "../../Components/inputs/LabeledInput";

export default function LoginPage() {
  const [currentPhase, setCurrentPhase] = React.useState("cover");

  const [data, setData] = React.useState({
    academicNumber: "",
  });
  const [error, setError] = React.useState("");

  const { setCurrentUser } = React.useContext(AppContext);

  const navigate = useNavigate();

  const onChangePhase = () => {
    setError("");
    setData({});
    if (currentPhase === "login") {
      setCurrentPhase("signup");
    } else {
      setCurrentPhase("login");
    }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setData({ ...data, [name]: value });
  };

  const onSubmit = async () => {
    let hasError = false;

    // Validate longin
    if (currentPhase === "login") {
      if (!data.email || !data.password) {
        setError("برجاء استكمال البيانات");
      } else {
        const { res, error } = await authApi({ data, type: "login" });

        if (error?.status) {
          setError("برجاء التأكد من صحة البيانات..  وإعادة المحاولة");
        }

        if (res?.data.message) {
          setError(res.data.message);
        }

        if (res?.data.accessToken) {
          setCurrentUser(res.data.accessToken);
        }
      }
    } else {
      signupInputs.forEach((input) => {
        if (input.name !== "email" && !data[input.name]) {
          setError(`برجاء إدخال ${input.label}`);
          hasError = true;
        }
      });

      if (data.academicNumber?.toString().length !== 9) {
        setError(
          "رجاء التأكد من إدخال الرقم الأكاديمى المكون من 9 أرقام بشكل صحيح."
        );
        hasError = true;
      }

      if (data.password !== data.confirmPassword) {
        setError("رجاء التأكد أن كلمة المرور مطابقة لإعادة كلمة المرور");
        hasError = true;
      }

      if (hasError) {
        return;
      }

      if (!error) {
        const { res, error } = await authApi({
          data: { ...data, email: data.academicNumber + postEmail },
          type: "signup",
        });

        if (error?.status) {
          setError(error.response.data.message);
        }
        // if (!res?.data.accessToken) {
        console.log({ res });
        if (res?.data?.status !== "accepted") {
          setError("برجاء التأكد من صحة البيانات..  وإعادة المحاولة");
        } else {
          // setCurrentUser(res.data.accessToken);
          navigate("/auth/post-register");
        }
      }
    }
  };
  return currentPhase === "cover" ? (
    <Box
    // sx={{
    //   backgroundImage: 'url("./images/cover.png")',
    //   backgroundSize: "cover",
    //   backgroundPosition: "center",
    //   minHeight: "40rem",
    // }}
    >
      <img src="./images/cover-2.png" alt="cover" style={{ width: "100%" }} />
      <Box sx={{ pt: { xs: 10, lg: 2 }, textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={() => {
            setCurrentPhase("login");
          }}
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            minWidth: "300px",
            display: { xs: "block", lg: "inline" },
            m: { xs: "10px auto", lg: 1 },
            borderRadius: 7,
            p: 1,
          }}
        >
          تسجيل الدخول
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setCurrentPhase("signup");
          }}
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            minWidth: "300px",
            display: { xs: "block", lg: "inline" },
            m: { xs: "10px auto", lg: 1 },
            borderRadius: 7,
            p: 1,
          }}
        >
          إنشاء حساب جديد
        </Button>
        <Button
          variant="outlined"
          target="_blank"
          component="a"
          href="https://youtu.be/o9MludhwB8U"
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            maxWidth: "300px",
            display: { xs: "block", lg: "inline" },
            m: { xs: "10px auto", lg: 1 },
            borderRadius: 7,
            p: { xs: 1, lg: 2 },
          }}
        >
          الذهاب لفيديو توضيحى للطالب ؟!
        </Button>{" "}
        <Button
          variant="outlined"
          target="_blank"
          component="a"
          href="https://youtu.be/evUHqze3Cy4"
          sx={{
            fontWeight: "bold",
            fontSize: "18px",
            maxWidth: "300px",
            display: { xs: "block", lg: "inline" },
            m: { xs: "10px auto", lg: 1 },
            borderRadius: 7,
            p: { xs: 1, lg: 2 },
          }}
        >
          الذهاب لفيديو توضيحى للموجه ؟!
        </Button>
      </Box>
      {/* <Button
        target="_blank"
        component="a"
        href="https://youtu.be/XWNmm3MYsb0"
        sx={{
          textDecoration: "underline",
          fontWeight: "600",
          display: "block",
          textAlign: "center",
          fontSize: "18px",
        }}
      >
        الذهاب لفيديو توضيحى للطالب ؟!
      </Button>{" "}
      <Button
        target="_blank"
        component="a"
        href="https://youtu.be/9N1SES86onE"
        sx={{
          textDecoration: "underline",
          fontWeight: "600",
          display: "block",
          textAlign: "center",
          fontSize: "18px",
        }}
      >
        الذهاب لفيديو توضيحى للموجه ؟!
      </Button> */}
    </Box>
  ) : (
    <Paper sx={{ width: { xs: "85%", md: "600px" }, m: " 20px auto", p: 2 }}>
      {(currentPhase === "login" ? loginInputs : signupInputs).map((input) => {
        return input.name === "gender" ? (
          <RadioInput
            label={input.label}
            name={input.name}
            value={data[input.name]}
            onChange={onChange}
            options={["ذكر", "أنثى"]}
            key={input.name}
          />
        ) : input.name === "email" && currentPhase === "signup" ? (
          <>
            <LabeledInput label={input.label} sx={{ mb: 0 }}>
              <Typography
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  border: "1px solid #bbb",
                  borderRadius: "4px",
                  p: 1,
                  color: "#888",
                  justifyContent: "right",
                }}
              >
                <span>{data.academicNumber ? data.academicNumber : ""}</span>
                <span>{postEmail.slice(1) + "@"}</span>
              </Typography>
            </LabeledInput>
            <Typography
              sx={{ fontSize: "13px", color: "#999", px: 1, mb: 1.5 }}
            >
              البريد الإلكترونى يتم كتابته تلقائيا عند كتابة الرقم الأكاديمى!
            </Typography>
          </>
        ) : (
          <TextInput
            label={input.label}
            value={data[input.name]}
            name={input.name}
            onChange={onChange}
            key={input.name}
            type={input.name === "academicNumber" && "number"}
          />
        );
      })}

      <Typography sx={{ color: "red", fontSize: "14px", textAlign: "center" }}>
        {error}
      </Typography>

      {currentPhase === "login" ? (
        <>
          <Button
            onClick={onChangePhase}
            sx={{ textDecoration: "underline", fontWeight: "600" }}
          >
            ليس عندى حساب, إنشاء حساب جديد ؟
          </Button>

          <Button
            onClick={() => {
              navigate(fbSendEmailPath);
            }}
            sx={{ textDecoration: "underline", fontWeight: "600" }}
          >
            أو نسيت كلمة المرور ؟
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={onChangePhase}
            sx={{ textDecoration: "underline", fontWeight: "600" }}
          >
            عندى حساب بالفعل ؟
          </Button>
        </>
      )}
      <Button
        onClick={onSubmit}
        variant="contained"
        fullWidth
        sx={{ mt: 1, fontSize: "18px" }}
      >
        {currentPhase === "login" ? "دخول حسابى" : "إنشاء حساب"}
      </Button>
      <Button
        onClick={() => {
          setCurrentPhase("cover");
        }}
        sx={{ textDecoration: "underline", fontWeight: "600" }}
      >
        الذهاب للرئيسية
      </Button>
    </Paper>
  );
}
