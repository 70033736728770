import { Box, Typography } from "@mui/material";
import React from "react";
import DoneAllIcon from "@mui/icons-material/DoneAll";

export default function PreviousMsg({ reply }) {
  return (
    <Box sx={{}}>
      <Typography
        sx={{
          fontSize: 14,
          display: "inline-block",
          backgroundColor: "primary.main",
          borderTopLeftRadius: 14,
          borderBottomLeftRadius: 14,
          borderBottomRightRadius: 14,
          p: 1,
          m: 0.25,
          color: "white",
        }}
      >
        <Box sx={{ pl: 12, fontWeight: "bold" }}>{reply.msg}</Box>

        <Box sx={{ textAlign: "left", fontStyle: "italic" }}>
          {reply.createdAt}
          <DoneAllIcon fontSize="small" sx={{ transform: "translateY(5px)" }} />
        </Box>
      </Typography>
    </Box>
  );
}
