import { Button } from "@mui/material";
import React from "react";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

export default function StudentSupportBtn({ showChat, setShowChat }) {
  const toggleStudentChatBtn = () => {
    setShowChat(!showChat);
  };

  return (
    <Button
      onClick={toggleStudentChatBtn}
      variant="contained"
      size="large"
      // sx={{ borderRadius: "50%", width: 60, height: 60 }}
    >
      <HeadsetMicIcon sx={{ fontSize: 25 }} />
      <span style={{ fontWeight: "bold", fontSize: 18, marginRight: 5 }}>
        الدعم الفنى
      </span>
    </Button>
  );
}
