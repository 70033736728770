import { Box, Typography } from "@mui/material";
import React from "react";

import SupportTable from "./Table";
import { data } from "./Table/dummyData";
import FilterManager from "./FilterManager";

export default function SupportPanel() {
  const [filterData, setFilterData] = React.useState({});

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h4" sx={{ fontWeight: "bold" }}>
        الدعم الفنى للمنصة:
      </Typography>

      <FilterManager />

      <SupportTable data={data} />
      <Typography
        sx={{ textAlign: "left", fontStyle: "italic", color: "#777" }}
      >
        سيتم إرسال رسالة بريد إلكترونى مرتين, مرة عند إستلام المشكلة ومرة عند
        إرسال رد عليها.
      </Typography>
    </Box>
  );
}
