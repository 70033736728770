import ParentPage from "../Pages/ParentPage";
import StudentPage from "../Pages/StudentPage";
import PreviousProblemsPage from "../Pages/StudentPage/PreviousProblemsPage";
import ProfileSettings from "../Pages/StudentPage/ProfileSettings";

export const previousProblemsPath = "/previous-problems";
export const profileSettingsPath = "/profile-settings";

export const studentRoutes = [
  {
    path: "/",
    element: <ParentPage />,
    children: [
      {
        path: "/",
        element: <StudentPage />,
      },
      {
        path: "/previousProblemsPath",
        element: <PreviousProblemsPage />,
      },
      {
        path: previousProblemsPath,
        element: <PreviousProblemsPage />,
      },
      {
        path: profileSettingsPath,
        element: <ProfileSettings />,
      },
    ],
  },
];
