import { Box, Button, Grid2, Typography } from "@mui/material";
import React from "react";
import Img from "./Img";
import Reply from "./Reply";
import PreviousMsg from "./PreviousMsg";

const noPreviousMsgsText = "لا يوجد رد من المنصة على المشكلة بعد.";

export default function SubRow({ row }) {
  const [currentReply, setCurrentReply] = React.useState("");
  const [currentComponent, setCurrentComponent] = React.useState(
    row.previousReplies?.[0] ? "سجل الرسائل السابقة" : "input"
  );

  const toggleCurrentComponent = () => {
    setCurrentComponent(
      currentComponent === "input" ? "previousMsgs" : "input"
    );
  };

  const onCommonReply = (reply) => {
    currentReply === reply ? setCurrentReply("") : setCurrentReply(reply);
  };

  return (
    <Box sx={{ textAlign: "right" }}>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 6 }}>
          <Img src={row.imgPath} />
        </Grid2>

        <Grid2 size={{ xs: 6 }}>
          {currentComponent === "input" ? (
            <Reply currentReply={currentReply} onCommonReply={onCommonReply} />
          ) : (
            <Box
              sx={{
                borderRadius: "10px",
                p: 2,
                border: "1px solid green",
                mb: 2,
                maxHeight: 400,
              }}
            >
              {row.previousReplies ? (
                <>
                  <Typography
                    variant="h6"
                    component="h2"
                    textAlign="right"
                    sx={{
                      color: "primary.main",
                      mb: 1,
                    }}
                  >
                    الرسائل السابقة :
                  </Typography>
                  {row.previousReplies.map((reply) => {
                    return <PreviousMsg key={reply.msg} reply={reply} />;
                  })}
                </>
              ) : (
                <Typography
                  sx={{
                    color: "#999",
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  {noPreviousMsgsText}
                </Typography>
              )}
            </Box>
          )}

          <Button variant="contained" onClick={toggleCurrentComponent}>
            {currentComponent === "input"
              ? "سجل الرسائل السابقة"
              : "إضافة رسالة"}
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
}
