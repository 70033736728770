import React from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function UnLabeledRadioBtns({ options }) {
  const [value, setValue] = React.useState("الكل");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup value={value} onChange={handleChange}>
        <FormControlLabel value="الكل" control={<Radio />} label="الكل" />
        {options.map((opt, i) => {
          return (
            <FormControlLabel
              value={opt}
              control={<Radio />}
              label={opt}
              key={i}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}
