export const levels = [
  {
    number: 1,
    label: "المستوى الاول",
  },
  {
    number: 2,
    label: "المستوى الثانى",
  },
  {
    number: 3,
    label: "المستوى الثالث",
  },
  {
    number: 4,
    label: "المستوى الرابع",
  },
  {
    number: 5,
    label: "المستوى الخامس",
  },
  {
    number: 6,
    label: "المستوى السادس",
  },
  {
    number: 7,
    label: "المستوى السابع",
  },
  {
    number: 8,
    label: "المستوى الثامن",
  },
];

export const optionalDepartmentsSubjects = [
  "اختياري تخصص 1",
  "اختياري تخصص 2",
  "اختياري تخصص 3",
];

export const optionalSubjects = [
  "اختياري جامعة 1",
  "اختياري جامعة 2",

  "اختياري كلية 1",
  "اختياري كلية 2",
  ...optionalDepartmentsSubjects,
];
