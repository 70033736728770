import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import SupportBtn from "../Components/Support/SupportBtn";

export default function VisitingParentPage() {
  return (
    <>
      <div id="detail">
        <Outlet />

        <Box sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 1000 }}>
          <SupportBtn />
        </Box>
      </div>
    </>
  );
}
