export const data = [
  {
    email: "123456729@student.kfu.edu.sa",
    msg: "هناك مشكلة تواجهنى عند إدخال مشكلة على المنصة",
    imgPath: "./images/problem.jpg",
    isConfirmedStudent: true,
    status: "تم حلها",
    category: "مشكلة تقنية",
    isRead: true,
    previousReplies: [
      { msg: "يتم الآن حل المشكلة, رجاء الأنتظار", createdAt: "2025-1-2" },
      {
        msg: "تم حل مشكلتك., رجاء إعاجة المحاولة الآن",
        createdAt: "2025-1-5",
      },
    ],
  },
  {
    email: "123356789@student.kfu.edu.sa",
    msg: "هناك مشكلة تواجهنى عند الدخول إالى المنصة",
    imgPath: "./images/problem.jpg",
    isConfirmedStudent: false,
    status: "مازالت يتم حلها",
    category: "",
    isRead: false,
  },
  {
    email: "124456789@student.kfu.edu.sa",
    msg: "هناك مشكلة تواجهنى عند الدخول, هذة مجرد معلومات لأختبار شكل هذه الصفحة",
    imgPath: "./images/problem.jpg",
    isConfirmedStudent: true,
    status: "مازالت يتم حلها",
    category: "مشكلة تقنية",
    isRead: false,
  },
  {
    email: "523456789@student.kfu.edu.sa",
    msg: "هناك مشكلة تواجهنى عند الدخول",
    imgPath: "./images/problem.jpg",
    isConfirmedStudent: true,
    status: "تم حلها",
    category: "مشكلة تقنية",
    isRead: true,
  },
];
