import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const downloadExcel = async (data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("جدول البيانات مرفق روابط الملفات");

  // Define columns
  const cols = [
    { header: "اسم الطالب", key: "اسم الطالب", width: 20 },
    { header: "البريد الإلكترونى", key: "البريد الإلكترونى", width: 35 },
    { header: "الجنس", key: "الجنس", width: 10 },
    { header: "رقم الجوال", key: "رقم الجوال", width: 20 },
    { header: "المستوى", key: "المستوى", width: 15 },
    { header: "الرقم الأكاديمى", key: "الرقم الأكاديمى", width: 15 },
    { header: "نوع المشكلة", key: "نوع المشكلة", width: 30 },
    { header: "تاريخ المشكلة", key: "تاريخ المشكلة", width: 15 },
    { header: "رابط الجدول الدراسى", key: "رابط الجدول الدراسى", width: 30 },
    { header: "رابط السجل الأكاديمى", key: "رابط السجل الأكاديمى", width: 30 },
    { header: "الحالة", key: "الحالة", width: 15 },
    { header: "عدد الساعات المكتسبة", key: "عدد الساعات المكتسبة", width: 25 },
    // {
    //   header: `المقرر (اسم - رقم - CRN - رمز الشعبة)`,
    //   key: `المقرر (اسم - رقم - CRN - رمز الشعبة)`,
    //   width: 50,
    // },

    { header: "اسم المقرر", key: "اسم المقرر", width: 30 },
    { header: "رقم المقرر", key: "رقم المقرر", width: 20 },
    { header: "CRN", key: "CRN", width: 15 },
    { header: "رمز الشعبة", key: "رمز الشعبة", width: 15 },

    { header: "القيود", key: "القيود", width: 40 },
    { header: "صورة من القيد", key: "صورة من القيد", width: 20 },
    {
      header: "عدد الساعات المطلوبة فى التسجيل",
      key: "عدد الساعات المطلوبة فى التسجيل",
      width: 40,
    },
  ];

  worksheet.columns = cols;
  // const centeredIndexes = [0, 1, 3, 4, 5, 6, 7, 9, , 10, 11, 13, 14, 16];

  for (let i = 0; i < cols.length; i++) {
    worksheet.getColumn(cols[i].key).alignment = {
      vertical: "middle",
      horizontal: "center",
    };
  }
  // console.log(cols[i].header);

  // const linksNames = [
  //   "رابط الجدول الدراسى",
  //   "رابط السجل الأكاديمى",
  //   "صورة من القيد",
  // ];

  // linksNames.forEach((n) => {
  //   worksheet.getColumn(n).eachCell((cell, rowNumber) => {
  //     console.log({ cell, rowNumber });
  //     if (rowNumber > 1) {
  //       cell.font = {
  //         color: { argb: "FF0a6a79" },
  //         underline: true,
  //         vertical: "middle",
  //         horizontal: "center",
  //       };
  //     }
  //   });
  // });

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true, size: 14, color: { argb: "FFFFFFFF" } }; // White text
    cell.alignment = { vertical: "middle", horizontal: "center" }; // Center alignment
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF0a6a79" }, // Green background
    };
    cell.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });

  // Add rows
  getExportedData(data).forEach((row) => {
    worksheet.addRow(row);
  });

  // Save the file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer]), "بيانات_الشكاوى.xlsx");
};

function getExportedData(data) {
  const exportData = data.map((row) => {
    const rowDate = new Date(row.createdAt);
    const date = rowDate.toISOString().split("T")[0];
    return {
      "اسم الطالب": row.user.username,
      "البريد الإلكترونى": row.user.email,
      الجنس: row.user.gender,
      "رقم الجوال": row.user.phone,
      المستوى: row.level,
      "الرقم الأكاديمى": row.user.academicNumber,
      "نوع المشكلة": row.problemType,
      "تاريخ المشكلة": date,
      "رابط الجدول الدراسى": {
        text: "رابط ملف الجدول الدراسى",
        hyperlink: process.env.REACT_APP_API + "/uploads/" + row.scheduleFile,
      },

      "رابط السجل الأكاديمى": {
        text: "رابط ملف الجدول الأكاديمي",
        hyperlink: process.env.REACT_APP_API + "/uploads/" + row.scheduleFile,
      },
      الحالة: row.state || "",
      "رسالة الحالة": row.stateMsg || "",
      "عدد الساعات المكتسبة": row.gainedHours || "",

      // ...problemSubjectsCols(row),
      "اسم المقرر": row.subjects?.[0].name || "",
      "رقم المقرر": row.subjects?.[0].number || "",
      CRN: row.subjects?.[0].crn || "",
      "رمز الشعبة": row.subjects?.[0].sectionNumber || "",

      القيود: row.constraints?.join(" - "),

      "صورة من القيد": {
        text: "رابط ملف القيد",
        hyperlink:
          process.env.REACT_APP_API + "/uploads/" + row.constraintFile || "",
      },

      "عدد الساعات المطلوبة فى التسجيل": row.requiredHours || "",
    };
  });

  return exportData;
}

function problemSubjectsCols(row) {
  let res = {};

  if (row.subjects?.[0]) {
    row.subjects.forEach((subject, i) => {
      res[
        `المقرر (اسم - رقم - CRN - رمز الشعبة)`
      ] = `${subject.name} - ${subject.number} - ${subject.crn} - ${subject.sectionNumber}`;
    });
  }

  return res;
}
