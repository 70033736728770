import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import TextInput from "../inputs/TextInput";
import { AppContext } from "../../Context/App.context";
import api from "../../api/axios";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import FileInput from "../inputs/FileInput";

export default function ChatBox() {
  const { user } = React.useContext(AppContext);

  const [data, setData] = React.useState({
    email: user.email || "",
    msg: "",
    // image: "",
  });

  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [loading, setLoading] = React.useState("");

  const onChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setSuccess("");
    setData({ ...data, [name]: value });
  };

  const onSelectFile = (e, name) => {
    const { files } = e.target;
    setError("");
    setSuccess("");

    console.log({ files: files[0] });
    setData({ ...data, [name]: files[0] });
  };

  const onSubmit = async () => {
    let reqBody = {};

    if (user.email) {
      reqBody = { email: user.email, msg: data.msg, isConfirmedStudent: "yes" };
    } else {
      reqBody = { ...data, isConfirmedStudent: "visitor" };
    }

    try {
      setLoading(true);
      const res = await api.post("msg", reqBody);
      if (res.data.saved) {
        setData({ ...data, msg: "" });
        setSuccess(
          "تم اضافة المشكلة التى تواجهك وسيتم الرد عبر البريد الإلكترونى فى أقرب وقت."
        );
      } else {
        setError("حدث خطأ ما, الرجاء إعادة المحاولة");
      }
      setLoading(false);
    } catch (error) {
      setError("حدث خطأ ما, الرجاء إعادة المحاولة");
      setLoading(false);
    }
  };

  return (
    <Paper
      position="absolute"
      sx={{
        borderRadius: "7px",
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mb: 1.5,
        "&::before": {
          content: '""',
          display: "block",
          position: "absolute",
          bottom: -10,
          right: 26,
          width: 10,
          height: 10,
          bgcolor: "background.paper",
          transform: "translateY(-50%) rotate(45deg)",
          zIndex: 0,
        },
      }}
    >
      <Box sx={{ p: 2, width: 300, overflow: "hidden" }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            pb: 2,
            color: "primary.main",
          }}
        >
          هل تواجه مشكلة ما فى المنصة ؟
        </Typography>
        <TextInput
          multiline
          rows={4}
          // maxRows={4}
          label=" وصف المشكلة"
          value={data.msg}
          name={"msg"}
          onChange={onChange}
        />

        {/* <FileInput
          label="صورة تصف المشكلة (إختيارى)"
          name="image"
          value={data.image?.name}
          onSelectFile={onSelectFile}
          type="img"
        /> */}

        {user.role === "visitor" && (
          <TextInput
            label="البريد الجامعى الخاص بك"
            value={data.email}
            name={"email"}
            onChange={onChange}
          />
        )}

        <Typography sx={{ color: "red", textAlign: "center" }}>
          {error}
        </Typography>
        <Typography sx={{ color: "primary.main", textAlign: "center" }}>
          {success}
        </Typography>
        <Box sx={{ mt: 1 }}>
          <LoadingButton
            onClick={onSubmit}
            variant="contained"
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            fullWidth
            sx={{ fontSize: "1.2rem" }}
            disabled={(!user.id && !data.email) || !data.msg}
          >
            <span style={{ paddingRight: "10px" }}>إرسال</span>
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
}
