import React from "react";
import { AppContext } from "../../Context/App.context";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import LabeledSelect from "../inputs/LabeledSelect";
import { departmentsOptions } from "../CategoryManager/utils";

const stateButtons = [
  { label: "قبول مبدئي" },
  { label: "قبول نهائي" },
  { label: "رفض مبدئي" },
  { label: "رفض نهائي" },
];

export default function RowStatus({
  row,
  setCurrentState,
  currentState,
  onCancelAddingState,
  onMsgChange,
  onSubmit,

  error,
  onDepartmentChange,
}) {
  const { user } = React.useContext(AppContext);

  const isSuperAdmin = user.department === "";

  const isForwarded =
    row.forwardedDepartment && user.department === row.forwardedDepartment;
  const isHisDepartment =
    user.department === row.department &&
    row.forwardedDepartment !== user.department;

  const enableForwarding =
    user.department === row.department || user.department === "";

  return (
    <Box sx={{ pt: 2, float: "left", minWidth: 400 }}>
      {row.forwardedDepartment && (isForwarded || isSuperAdmin) && (
        <Typography variant="h6" sx={{ fontWeight: "600", mb: 1 }}>
          موجهة من: <span style={{ color: "green" }}>{row.department}</span>
        </Typography>
      )}

      {row.forwardedDepartment && isHisDepartment && (
        <Typography variant="h6" sx={{ fontWeight: "600", mb: 1 }}>
          وُجهت إلى:{" "}
          <span style={{ color: "green" }}>{row.forwardedDepartment}</span>
        </Typography>
      )}

      {row.state && (
        <>
          <Paper
            sx={{ p: 2, ml: 2, mb: 2, fontWeight: "600", maxWidth: "400px" }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 1, fontWeight: "600", color: "primary.main" }}
            >
              الحالة الحالية : {row.state}
            </Typography>
            {row.stateMsg && <Typography>الرسالة: {row.stateMsg}</Typography>}
          </Paper>
          {isHisDepartment && row.forwardedDepartment ? (
            ""
          ) : (
            <Divider sx={{ mb: 2 }}>تغيير الحالة</Divider>
          )}
        </>
      )}

      {isHisDepartment && row.forwardedDepartment ? (
        ""
      ) : (
        <Box>
          {stateButtons.map((btn) => {
            return (
              <Button
                onClick={() => {
                  setCurrentState({ state: btn.label, submitted: false });
                }}
                variant="contained"
                sx={{ ml: 2, fontWeight: "600" }}
              >
                {btn.label}
              </Button>
            );
          })}
        </Box>
      )}
      <Box sx={{ pl: 2 }}>
        {currentState.state && (
          <Paper sx={{ mt: 2, p: 2, position: "relative" }}>
            <IconButton
              onClick={onCancelAddingState}
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                color: "primary.main",
              }}
            >
              <CancelIcon />
            </IconButton>
            <Typography sx={{ color: "primary.main" }}>
              الحالة : {currentState.state}
            </Typography>
            <Box sx={{ pt: 1, pl: 2 }}>
              <TextField
                multiline
                fullWidth
                value={currentState.stateMsg}
                onChange={onMsgChange}
                maxRows={7}
                placeholder="إرسال رسالة نصية"
              />
              <Button
                onClick={onSubmit}
                variant="contained"
                fullWidth
                sx={{ mt: 1 }}
              >
                موافقة
              </Button>

              <Typography sx={{ textAlign: "center", color: "red" }}>
                {error}
              </Typography>
            </Box>
          </Paper>
        )}

        {enableForwarding && (
          <Box sx={{ flexGrow: 1 }}>
            <Divider sx={{ mt: 2 }}>أو توجيه الشكوى لتخصص آخر</Divider>

            <LabeledSelect
              label="تحديد التخصص"
              options={departmentsOptions.map((d) =>
                d.label === "آخرى" ? "مسجل الكلية" : d.label
              )}
              placeholder={"التخصص الموجة إليه المشكلة"}
              value={currentState.forwardedDepartment}
              onChange={onDepartmentChange}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
