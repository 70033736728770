import { Box, Grid2, Typography } from "@mui/material";
import React from "react";
import DepartmentsTabs from "./DepartmentsTabs";
import { departmentsOptions } from "../../Components/CategoryManager/utils";
import StudentForm from "./StudentForm";
import { getInitialProblemData, studentHeaderLines } from "./utils";

export default function StudentPage() {
  const initialProblemData = getInitialProblemData({
    department: "",
  });

  const [problemData, setProblemData] = React.useState(initialProblemData);

  const onSelectDepartment = (department) => {
    setProblemData({ ...problemData, department });
  };

  // console.log("problemData", problemData);

  return (
    <Box sx={{ pb: 5 }}>
      <Box sx={{ textAlign: "center", my: 4 }}>
        {studentHeaderLines.map((line) => {
          return (
            <Typography
              variant="h4"
              sx={{ color: line.color, fontSize: "22px", fontWeight: "600" }}
              key={line.text}
            >
              {line.text}
            </Typography>
          );
        })}
      </Box>

      <DepartmentsTabs
        currentDepartment={problemData.department}
        onSelectDepartment={onSelectDepartment}
      />

      {departmentsOptions.find((d) => d.label === problemData.department)
        ?.department ? (
        <Grid2 container sx={{ mt: 3, flexDirection: "row-reverse" }}>
          <Grid2 size={{ xs: 12, md: 6 }} sx={{ textAlign: "center" }}>
            <img
              style={{ maxWidth: "90%" }}
              src={
                "/images/" +
                departmentsOptions.find(
                  (d) => d.label === problemData.department
                ).department +
                ".png"
              }
              alt="logo"
            />
          </Grid2>

          <Grid2 size={{ xs: 12, md: 6 }}>
            <StudentForm
              problemData={problemData}
              setProblemData={setProblemData}
            />
          </Grid2>
        </Grid2>
      ) : (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <img
            src={"/images/warning.png"}
            alt="logo"
            style={{ maxWidth: "90%" }}
          />
        </Box>
      )}
    </Box>
  );
}
