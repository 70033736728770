import { Box, Button, styled } from "@mui/material";
import React from "react";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import LabeledInput from "./LabeledInput";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function FileInput({ label, name, value, onSelectFile, type }) {
  console.log({ value });
  return (
    <Box sx={{ mt: 1 }}>
      <LabeledInput label={label}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
            flexGrow: 1,
            pl: 1,
          }}
        >
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{
              borderTopLeftRadius: value && "0",
              borderBottomLeftRadius: value && "0",
              pr: 1,
            }}
          >
            <span style={{ paddingRight: "10px" }}>أختر</span>{" "}
            <VisuallyHiddenInput
              type="file"
              accept={type === "img" ? "image/*" : ".pdf"}
              onChange={(e) => onSelectFile(e, name)}
            />
          </Button>

          <Box
            sx={{
              display: "inline-block",
              border: value && "1px solid #999",
              borderColor: "primary.main",
              p: value ? 1.05 : 0,
              borderTopLeftRadius: "5px",
              borderBottomLeftRadius: "5px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              fontSize: "14px",
              color: "#444",
            }}
          >
            {value}
          </Box>
        </Box>
      </LabeledInput>
    </Box>
  );
}
