import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import TextInput from "../../../../Components/inputs/TextInput";

const commonReplies = [
  "تم حل مشكلتك., رجاء إعاجة المحاولة الآن",
  "يتم الآن حل المشكلة, رجاء الأنتظار",
  "البريد الإلكترونى غير صحيح",
  "أو إاضافة اى رد سريع هنا ...",
];

export default function Reply({ currentReply, onCommonReply }) {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: "600",
          color: "primary.oldMain",
          pl: 2,
        }}
      >
        الرد عبر البريد الإلكترونى :
      </Typography>

      <Box sx={{ my: 2 }}>
        {commonReplies.map((reply) => {
          return (
            <Button
              variant={currentReply === reply ? "contained" : "outlined"}
              key={reply}
              sx={{ borderRadius: 10, m: 0.5 }}
              onClick={() => onCommonReply(reply)}
            >
              {reply}
            </Button>
          );
        })}

        <Box />
      </Box>

      <Divider>أو </Divider>

      <TextInput
        multiline
        rows={4}
        label="إرفاق رسالة نصية"
        // value={data.msg}

        name={"msg"}
        // onChange={onChange}
      />
      <Button variant="contained" sx={{ float: "left" }}>
        إرسال
      </Button>
    </Box>
  );
}
