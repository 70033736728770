import AdminPage from "../Pages/AdminPage";
import ParentPage from "../Pages/ParentPage";
import SupportPanel from "../Pages/SupportPanel";

export const supportPanelPath = "/support-panal";

export const adminRoutes = [
  {
    path: "/",
    element: <ParentPage />,

    children: [
      {
        path: "/",
        element: <AdminPage />,
      },
      {
        path: supportPanelPath,
        element: <SupportPanel />,
      },
    ],
  },
];
