import { Box, IconButton, Tooltip, Typography } from "@mui/material";

import DescriptionIcon from "@mui/icons-material/Description";
// import { downloadFile } from "../../../api/files";

import DangerousIcon from "@mui/icons-material/Dangerous";
import { BASE_URL } from "../../../api/axios";

export const columns = [
  {
    accessorKey: "user.username",
    header: "الأسم",
    size: 50,
  },
  {
    accessorKey: "user.email",
    header: "البريد الإلكترونى",
    size: 200,
  },
  {
    accessorKey: "user.gender",
    header: "الجنس",
    size: 30,
  },
  {
    accessorKey: "user.phone",
    header: "رقم الجوال",
    size: 50,
  },
  {
    accessorKey: "level",
    header: "المستوى",
    size: 50,
  },
  {
    accessorKey: "user.academicNumber",
    header: "الرقم الأكاديمى",
    size: 50,
  },
  {
    accessorKey: "problemType",
    header: "نوع المشكلة",
    size: 50,
  },
  {
    accessorKey: "createdAt",
    header: "تاريخ المشكلة",
    size: 50,
  },
  {
    accessorKey: "scheduleFile",
    header: "الجدول الدراسى",
    size: 10,
    enableColumnActions: false,

    Cell: ({ row }) => {
      return (
        row.original.scheduleFile && (
          <Box sx={{ textAlign: "center" }}>
            <Tooltip title="تحميل الجدول الدراسى" placement="top">
              <IconButton
                sx={{ m: "auto" }}
                // onClick={() => downloadFile(row.original.scheduleFile)}
              >
                <a
                  href={BASE_URL + "/uploads/" + row.original.scheduleFile}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#777" }}
                >
                  <DescriptionIcon color="text.secondary" />
                </a>
              </IconButton>
            </Tooltip>
          </Box>
        )
      );
    },
  },
  {
    accessorKey: "academicFile",
    header: "السجل الأكاديمى",
    size: 10,
    enableColumnActions: false,

    Cell: ({ row }) => {
      return (
        row.original.academicFile && (
          <Box sx={{ textAlign: "center" }}>
            <Tooltip title="تحميل السجل الأكاديمى" placement="top">
              <IconButton
                sx={{ m: "auto" }}
                // onClick={() => downloadFile(row.original.scheduleFile)}
              >
                <a
                  href={BASE_URL + "/uploads/" + row.original.academicFile}
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#777" }}
                >
                  <DescriptionIcon color="text.secondary" />
                </a>
              </IconButton>
            </Tooltip>
          </Box>
        )
      );
    },
  },

  {
    accessorKey: "state",
    header: "الحالة",
    size: 50,
    enableColumnActions: false,

    Cell: ({ row }) => {
      const state = row.original.state;
      return state === "تم الرفض" ? (
        <Typography
          sx={{ color: "red", display: "flex", justifyContent: "center" }}
        >
          {state} <DangerousIcon />
        </Typography>
      ) : state === "تم القبول" ? (
        <Typography sx={{ color: "green" }}>{state}</Typography>
      ) : (
        <Typography sx={{ color: "primary.main" }}>{state}</Typography>
      );
    },
  },
];
